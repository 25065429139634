import "./styles.scss"

import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import { motion } from "framer-motion"

import SectionHeader from "components/SectionHeader"
import WallpaperTile from "components/WallpaperTile"
import Button from "components/Button"

import { useProducts } from "context/products"
import { useLocale } from "context/locale"

import fadeAnimation from "utils/fadeAnimation"

const Bestsellers = ({ rmAction, header, subHeader, productsObject }) => {
  const { t, lang } = useLocale()
  const isBrowser = typeof window !== "undefined"
  const { products, productsEN } = useProducts()
  const [filtered_products, setFilteredProducts] = useState(null)
  const [productsObjectFiltered, setProductsObjectFiltered] = useState(null)

  useEffect(() => {
    isBrowser &&
      setProductsObjectFiltered(
        productsObject?.map(item => {
          return item.id
        })
      )
  }, [productsObject])

  useEffect(() => {
    setFilteredProducts(
      lang === "en"
        ? productsEN?.filter(product =>
            productsObjectFiltered?.includes(product.id)
          )
        : products?.filter(product =>
            productsObjectFiltered?.includes(product.id)
          )
    )
  }, [productsObjectFiltered])

  const slider = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 750,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    draggable: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    filtered_products?.length > 0 && (
      <section className="section-bestsellers">
        <div className="container-fluid">
          <SectionHeader
            title={header ? header : t("Bestsellery")}
            content={subHeader ? subHeader : t("- znajdź swoją inspirację")}
          />
        </div>
        <motion.div className="section-bestsellers__slider" {...fadeAnimation}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-11 offset-xxl-1">
                <Slider {...slider}>
                  {filtered_products?.map((item, index) => (
                    <div key={index}>
                      <WallpaperTile item={item} marginZero />
                    </div>
                  ))}
                  <div></div>
                </Slider>
              </div>
            </div>
          </div>
        </motion.div>
        {!rmAction && (
          <motion.div className="section-bestsellers__cta" {...fadeAnimation}>
            <div className="container-fluid">
              <Button to={t("/bestseller/")} className="button--bordered">
                {t("Zobacz więcej")}
              </Button>
            </div>
          </motion.div>
        )}
      </section>
    )
  )
}

export default Bestsellers
