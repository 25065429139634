import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Newsletter from "components/Newsletter"
import Bestsellers from "components/Bestsellers"

import {
  Hero,
  Interior,
  Project,
  Quality,
  Check,
  Grid,
  Passion,
  Cooperation,
  Join,
} from "page_components/home"

const Home = ({ data, location }) => {
  const pageData = data?.allWpPage?.nodes[0]

  return (
    <Layout location={location} isHome>
      <Hero pageData={pageData} />
      <Interior pageData={pageData} />
      <Bestsellers
        header={pageData?.acfHome?.wallpapersSliderHeader}
        subHeader={pageData?.acfHome?.wallpapersSliderSubHeader}
        productsObject={
          pageData?.acfHome?.wallpapersSliderProductsCustomRelation
        }
      />
      <Project pageData={pageData} />
      <Quality pageData={pageData} />
      <Check pageData={pageData} />
      <Grid pageData={pageData} />
      <Passion pageData={pageData} />
      <Cooperation pageData={pageData} />
      <Join pageData={pageData} />
      <Newsletter />
    </Layout>
  )
}

export const Head = ({ data }) => (
  <Seo
    title={
      data?.allWpPage?.nodes[0]?.seo?.title
        ? data?.allWpPage?.nodes[0]?.seo?.title
        : data?.allWpPage?.nodes[0]?.title
    }
    description={
      data?.allWpPage?.nodes[0]?.seo?.metaDesc &&
      data?.allWpPage?.nodes[0]?.seo?.metaDesc
    }
  />
)

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoxMDA2MQ==" } }) {
      nodes {
        seo {
          title
          metaDesc
        }
        acfHome {
          heroSlider {
            background {
              localFile {
                publicURL
              }
            }
            header
            subHeader
            description
            bestsellerImage {
              localFile {
                publicURL
              }
            }
            bestsellerTitle
            bestsellerLink
          }
          interiorHeader
          interiorDescription
          interiorProductName
          interiorProductLink
          interiorProductTextures {
            name
            mainImage {
              localFile {
                publicURL
              }
            }
            textureImage {
              localFile {
                publicURL
              }
            }
          }
          wallpapersSliderHeader
          wallpapersSliderSubHeader
          wallpapersSliderProductsCustomRelation {
            id
          }
          projectHeader
          projectSubHeader
          projectDescription
          projectVideoPoster {
            localFile {
              publicURL
            }
          }
          qualityHeader
          qualitySubHeader
          qualityContentHeader
          qualityContentDescription
          qualityContentImage {
            localFile {
              publicURL
            }
          }
          qualityContent {
            image {
              localFile {
                publicURL
              }
            }
            header
            description
            buttonLink
          }
          checkHeader
          checkDescription
          checkImage {
            localFile {
              publicURL
            }
          }
          gridProducts {
            url
            image {
              localFile {
                publicURL
              }
            }
          }
          passionHeader
          passionDescription
          cooperationHeader
          cooperationImages {
            localFile {
              publicURL
            }
          }
          joinHeader
          joinDescription
          joinTiles {
            background {
              localFile {
                publicURL
              }
            }
            header
            link
          }
        }
      }
    }
  }
`

export default Home
